.navigation {
  &__checkbox {
    display: none;
  }

  &__button {
    background-color: white;
    height: 4rem;
    width: 4rem;
    position: fixed;
    top: 3rem;
    right: 3rem;
    border-radius: 50%;
    z-index: 16;
    box-shadow: 0.5rem 0.5rem 1rem rgba(black, 0.2);

    text-align: center;
    cursor: pointer;
  }

  &__background {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    position: fixed;
    top: 3rem;
    right: 3rem;
    background-image: radial-gradient(blue, yellow);
    z-index: 10;

    transition: transform 0.5s;
  }

  &__nav {
    display: none;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;

    opacity: 0;
    width: 0;

    transition: all 0.5s;
  }

  &__list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    width: 100%;
  }

  &__item {
    margin: 1rem;
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      font-size: 3rem;
      font-weight: 300;
      padding: 1rem 1rem;
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      background-image: linear-gradient(
        120deg,
        transparent 0%,
        transparent 50%,
        white 50%,
        white 100%
      );
      background-size: 240%;
      transition: all 0.4s;
    }

    &:hover,
    &:active {
      background-position: 100%;
      color: blue;
      transform: translateX(0.5rem);
    }
  }

  // Checkbox hack (select siblings)

  &__checkbox:checked ~ &__background {
    transform: scale(80);
  }

  &__checkbox:checked ~ &__nav {
    width: 100%;
    opacity: 1;
    display: inline-block;
  }

  // Hamburger icon
  &__icon {
    position: relative;
    margin-top: 2rem;

    &,
    &::before,
    &::after {
      width: 2rem;
      height: 2px;
      background-color: #333;
      display: inline-block;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }

    &::before {
      top: -0.7rem;
    }

    &::after {
      top: 0.7rem;
    }
  }

  // Transform on hover

  &__button:hover &__icon::before {
    top: -0.8rem;
  }

  &__button:hover &__icon::after {
    top: 0.8rem;
  }

  // Transform on click

  &__checkbox:checked + &__button &__icon {
    background-color: transparent;
  }

  &__checkbox:checked + &__button &__icon::before {
    top: 0;
    transform: rotate(135deg);
  }

  &__checkbox:checked + &__button &__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }
}

// Hide nav button on mobile
@media only screen and (max-width: 420px) {
  .navigation {
    display: none;
  }
}
