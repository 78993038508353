.ScrollDownArrow {
  display: block;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
  animation: bounce 3s linear infinite;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.fadeIn {
  animation: bounce 4s linear infinite, fadeIn 2s linear 2s 1 backwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-2rem);
  }
}

@media only screen and (min-width: 421px) {
  .ScrollDownArrow {
    display: none;
  }
}
