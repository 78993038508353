.footer {
  display: flex;
  justify-content: center;
  min-width: 100vw;
  background-color: rgba(250, 250, 250, 0.1);
  color: rgba(56, 100, 122, 1);
  margin-right: 20px;

  p {
    margin: 1rem;
    list-style: none;
    font-size: 1rem;
  }

  a {
    color: rgba(56, 100, 122, 1);
  }
}

@media only screen and (min-width: 800px) {
  .footer p {
    font-size: 1.3rem;
  }
}
