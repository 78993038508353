@import "../constants";
@import "../page";

.LandingPage {
  @include pagebase;
  background-image: linear-gradient($color-primary-light, $color-primary-dark);
  color: azure;

  h1 {
    text-align: center;
    padding-top: 10rem;
    text-transform: uppercase;
    margin-top: 2rem;
  }

  h2 {
    text-align: center;
    font-size: 1.3rem;
  }

  ul {
    text-align: center;
    list-style: none;
    margin-top: 5rem;

    li {
      display: inline-block;
      margin-right: 1rem;
      font-size: 1rem;
      border-radius: 1rem;
    }
  }

  .name {
    font-size: 1.5rem;
    animation-name: FadeInFromRight;
    animation-duration: 1.8s;
  }

  .title {
    font-size: 1.2rem;
    animation-name: FadeInFromLeft;
    animation-duration: 1.8s;
  }

  .social {
    margin-top: 1.5rem;

    animation-name: FadeInFromBottom;
    animation-duration: 0.7s;
    animation-delay: 1.8s;
    animation-fill-mode: backwards;

    display: flex;
    justify-content: center;
    align-items: center;

    &-logo {
      display: inline-block;
      padding: 0.5rem;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }

  @keyframes FadeInFromRight {
    0% {
      opacity: 0;
      transform: translateX(10rem);
    }

    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes FadeInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-10rem);
    }

    100% {
      opacity: 1;
      transform: translate(0);
    }
  }

  @keyframes FadeInFromBottom {
    0% {
      opacity: 0;
      transform: translateY(5rem);
    }

    100% {
      opacity: 1;
      transform: translate(0);
    }
  }
}

// Phone (landscape or > iPhone 5)
@media only screen and (min-width: 320px) {
  .LandingPage {
    .name {
      font-size: 2rem;
    }

    .title {
      font-size: 1.6rem;
    }

    .social-logo {
      padding: 1rem;
    }
  }
}

// Tablet (portrait)
@media only screen and (min-width: 580px) {
  .LandingPage {
    .name {
      font-size: 2.8rem;
    }

    .title {
      font-size: 1.8rem;
    }
  }
}

// Tablet (landscape)
@media only screen and (min-width: 768px) {
  .LandingPage {
    .name {
      font-size: 3.8rem;
    }

    .title {
      font-size: 2.5rem;
    }
  }
}

// Desktop
@media only screen and (min-width: 1024px) {
  .LandingPage {
    h1 {
      margin-top: 7rem;
    }

    .name {
      font-size: 5.5rem;
    }

    .title {
      font-size: 3rem;
    }

    .social-logo {
      padding: 2rem;

      svg {
        width: 60px;
        height: 60px;
      }
    }
  }
}

// 4K
@media only screen and (min-width: 2160px) {
  .LandingPage {
    .name {
      font-size: 7rem;
    }

    .title {
      font-size: 4rem;
    }
  }
}
