@import "../page.scss";

.ProjectsPage {
  @include pagebase;

  .ProjectsContainer {
    margin: 2rem 0;

    font-size: 1.5rem;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
