@import "../../constants";

.contactform {
  text-align: center;
  width: 45rem;
  margin: 0 auto;
}

.contactform-textinputs {
  display: flex;
  margin: auto;
}

.contactform-input {
  flex-grow: 1;
  margin-bottom: 0.5rem;
}

.contactform-textinput {
  flex-direction: row;
  flex-grow: 1;
}

.inputform-label {
  display: flex;
  flex-direction: row;
}

.contactform-labeltext {
  font-size: 1rem;
  margin-right: 0.4rem;
  margin-left: 0.3rem;
}

input,
textarea {
  padding: 0.5rem;
  resize: none;
  max-width: 90vw;
}

input[type="text"],
input[type="email"] {
  height: 2rem;
  max-width: 70vw;
}

// TODO: responsiveness improvements.
// Wide screen:
// Your name | Your email
// textarea
// Medium screen:
// Your name
// Your email
// textarea

textarea {
  width: 100%;
  height: 18rem;
}

.submit-container {
  margin-bottom: 2rem;
}

.submit-button {
  background-color: $color-submit-button;
  color: white;
  font-weight: 500;
  height: 2.5rem;
  width: 4rem;
}

.submit-button:hover {
  cursor: pointer;
}

.submit-button:active {
  background-color: $color-submit-button-active;
}

.email-success {
  font-size: 0.8rem;
  color: green;
}

.email-error {
  font-size: 0.8rem;
  color: rgb(248, 93, 93);
}

@media only screen and (max-width: 1200px) {
  .contactform {
    width: 60vw;
  }
}

@media only screen and (max-width: 1023px) {
  .contactform-textinputs {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .contactform-textinputs {
    display: block;
  }

  .inputform-label {
    flex-direction: column;
  }
}
