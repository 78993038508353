@import "../page.scss";

.SkillsPage {
  @include pagebase;

  .RadarChart {
    display: flex;
    justify-content: center;

    svg {
      margin-top: 3rem;
      justify-content: center;
      width: 90%;

      g text {
        font-size: 1rem;
      }
    }
  }
}
