@import "./constants";

@mixin pagebase {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: $color-text;
  background-color: $color-background;
  margin-bottom: 0;

  h1 {
    font-size: 3rem;
    text-align: center;
    margin-top: 2rem;
  }

  p {
    font-size: 1.2rem;
    text-align: justify;

    margin: {
      left: 1rem;
      right: 1rem;
      top: 0px;
      bottom: 1rem;
    }

    a:link,
    a:visited,
    a:active {
      color: $color-text;
    }

    a:hover {
      text-decoration: none;
    }
  }

  li {
    margin: 0 1rem;
  }

  .arrow {
    margin-top: auto;
    text-align: center;
    margin-bottom: 2rem;
  }

  @media only screen and (min-width: 768px) {
    p {
      font-size: 1.6rem;
      margin-left: 2rem;
      margin-right: 2rem;
    }

    li {
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    p {
      font-size: 1.6rem;
      margin-left: 4rem;
      margin-right: 4rem;
    }

    li {
      font-size: 1rem;
    }
  }
}
