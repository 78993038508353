* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  background-color: white;
  min-height: 100vh;
  user-select: none;
}

body {
  font-size: 62.5%;
  overflow-x: hidden;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  /* 1.7 times the predifined line height */
  color: #777;
}
